import { PopoverInteractionKind, TooltipProps } from "@blueprintjs/core";
import { FeatureGateClickedSource, OrgRole } from "@hex/common";
import React, { ReactNode, useCallback } from "react";

import { HexCleanLink, HexTooltip } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { Routes } from "../../route/routes";
import { trackFeatureGateClicked } from "../../util/featureGateUtils";
import { ContactAnAdmin } from "../common/ContactAnAdmin";

import {
  FeatureGateCTAProps,
  FeatureGateZeroState,
  FeatureGateZeroStateProps,
} from "./FeatureGateZeroState";

export type FeatureGateCardToolTipProps = {
  children?: ReactNode;
  disabled: boolean;
};

export const FeatureGateCardToolTip: React.ComponentType<
  FeatureGateCardToolTipProps & FeatureGateZeroStateProps
> = React.memo(function FeatureGateCardToolTip({
  children,
  disabled,
  ...props
}: FeatureGateCardToolTipProps & FeatureGateZeroStateProps) {
  return (
    <HexTooltip
      content={<FeatureGateZeroState {...props} />}
      disabled={disabled}
      hoverCloseDelay={100}
      interactionKind={PopoverInteractionKind.HOVER}
      placement="bottom"
      targetTagName="div"
      useAppTheme={true}
    >
      {children}
    </HexTooltip>
  );
});

export type FeatureGateToolTipProps = Omit<
  TooltipProps,
  "hoverCloseDelay" | "interactionKind"
> & {
  readonly children?: ReactNode;
  shouldStopPropagation?: boolean;
};

export const FeatureGateToolTip: React.ComponentType<
  FeatureGateToolTipProps & FeatureGateCTAProps
> = React.memo(function FeatureGateToolTip({
  children,
  content,
  featureGate,
  shouldStopPropagation = false,
  ...props
}: FeatureGateToolTipProps & FeatureGateCTAProps) {
  return (
    <HexTooltip
      content={
        <FeatureGateToolTipContent
          content={content}
          featureGate={featureGate}
          shouldStopPropagation={shouldStopPropagation}
        />
      }
      hoverCloseDelay={100}
      interactionKind={PopoverInteractionKind.HOVER}
      placement={props.placement ?? "bottom"}
      targetTagName="div"
      {...props}
    >
      {children}
    </HexTooltip>
  );
});

export const FeatureGateToolTipContent: React.ComponentType<
  Pick<FeatureGateCTAProps, "featureGate"> &
    Pick<FeatureGateToolTipProps, "content" | "shouldStopPropagation">
> = React.memo(function FeatureGateToolTipContent({
  content,
  featureGate,
  shouldStopPropagation = false,
}: FeatureGateToolTipProps & FeatureGateCTAProps) {
  const subView = "tiers";
  const currentUser = useCurrentUser();
  const isAdmin = currentUser?.orgRole === OrgRole.ADMIN;

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (shouldStopPropagation) {
        e.stopPropagation();
      }
      trackFeatureGateClicked({
        feature: featureGate,
        source: FeatureGateClickedSource.FEATURE_DISABLED,
        orgRole: currentUser?.orgRole,
      });
    },
    [currentUser?.orgRole, featureGate, shouldStopPropagation],
  );

  // keep the tooltip open long enough to mouse over
  // see: https://github.com/palantir/blueprint/issues/4727
  return (
    <DiamondToolTipWrapper>
      <div>
        A higher plan is required to {content}.
        {isAdmin ? (
          <>
            {" "}
            <HexCleanLink
              target="_blank"
              to={Routes.SETTINGS.getUrl({
                subView,
              })}
              onClick={onClick}
            >
              Compare all plans.
            </HexCleanLink>
          </>
        ) : (
          <>
            {" "}
            <ContactAnAdmin
              shouldStopPropagation={shouldStopPropagation}
              text="Contact your admin"
            />{" "}
            to request an upgrade.
          </>
        )}
      </div>
    </DiamondToolTipWrapper>
  );
});

interface DiamondToolTipWrapperProps {
  children?: ReactNode;
}

export const DiamondToolTipWrapper: React.ComponentType<DiamondToolTipWrapperProps> =
  React.memo(function FeatureGateToolTipWrapper({
    children,
  }: DiamondToolTipWrapperProps) {
    return (
      <div
        css={`
          display: flex;
          align-items: top;
        `}
      >
        <span
          css={`
            font-size: 16px;
            width: 30px;
            text-align: center;
            margin-right: 4px;
            margin-top: 2px;
          `}
        >
          💎
        </span>
        {children}
      </div>
    );
  });
